import axios from 'axios'
import { Message } from 'element-ui'
import store from '@/store'
import { getToken, removeToken} from '@/utils/auth'
// import { getLanCode, convertLongLang } from '@/lang'

import router from '@/router'
import Config from '@/config'
// import { saveAs } from 'file-saver'
axios.defaults.withCredentials = true
// 创建axios实例
const service = axios.create({
  // baseURL: process.env.BASE_API, // api 的 base_url
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: true, // send cookies when cross-domain requests
  timeout: Config.timeout // 请求超时时间
})

function fileNameFromHeader(disposition) {
  let result = null
  disposition = disposition.split(';')[1]
  if (disposition && /filename=.*/gi.test(disposition)) {
    result = disposition.match(/filename=.*/gi)
    return decodeURIComponent((result[0].split('=')[1]).replace(/\+/g, '%20'))
  }
  return null
}
// request拦截器
service.interceptors.request.use(
  config => {
    
    // const token = getToken()
    // if (token) {
    //   config.headers['token'] = token // 让每个请求携带自定义token
    // }
    // config.headers['Content-Type'] = 'application/json;charset=utf-8'
    // config.headers['Content-Type'] = 'application/json;'
    // config.headers['Access-Control-Allow-Credentials']=true
    // const lanCode = convertLongLang(getLanCode())
    // config.headers['lan-code'] = lanCode
    // if (config.method === 'post' || config.method === 'put' || config.method === 'delete') {
    //   config.data = {
    //     ...config.data,
    //     // lanCode: lanCode
    //   }
    // }
    return config
  },
  error => {
    // Do something with request error
    Message({
      message: 'request error',
      type: 'error',
      duration: 5 * 1000
    })
    console.log(error,"11") // for debug
    Promise.reject(error)
    console.log(process.env.VUE_APP_BASE_API);
  }
)
// const that=this
// response 拦截器
service.interceptors.response.use(
  response => {
    const res = response.data
    if (response.config.responseType === 'blob') {
      if (res.size === 0) {
        Message({
          message: '导出错误',
          type: 'error',
          dangerouslyUseHTMLString: true,
          duration: 3 * 1000
        })
        return Promise.reject(res.msg || 'export error')
      }
      // 文件下载
      /* let fileName = response.config.fileName
      if (!fileName) {
        fileName = Date.parse(new Date())
      }
      if (window.navigator.msSaveOrOpenBlob) {
        navigator.msSaveBlob(res, fileName)
      } else {
        var link = document.createElement('a')
        link.href = window.URL.createObjectURL(res)
        link.download = fileName
        link.click()
        // 释放内存
        window.URL.revokeObjectURL(link.href)
      } */
      let fileName = response.config.fileName
      if (!fileName) {
        // 有文件名就用自定义的，没有就从header获取
        fileName = fileNameFromHeader(response.headers['Content-Disposition'] || '') || Date.parse(new Date())
      }
      // const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' })
      // saveAs(blob, fileName)
      return null
    }
    if (response.data.code === undefined) {
      if (response.status === 200) {
        return response.data
      }
      return
    }
    if (res.code !== 200) {
      Message({
        message: res.msg || 'error',
        type: 'error',
        dangerouslyUseHTMLString: true,
        duration: 5 * 1000
      })
      if (res.code === '-99') {
        // to re-login
        /*  MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
          confirmButtonText: 'Re-Login',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }).then(() => {  */
        store.dispatch('RemoveToken').then(() => {
          location.reload()
          /* this.$router.push({
            path: `/login`
          })*/
        })
      }
      return Promise.reject(res.msg || 'error')
    } else {
      if (res.msg) {
        Message({
          message: res.msg || 'success',
          type: 'success',
          dangerouslyUseHTMLString: true,
          duration: 3 * 1000
        })
      }
      return res
    }
  },
  error => {
    console.log('err' + error) // for debug
    if (error.response) {
      if (error.response.status === 401) {
              // 跳转登录页
              router.push(
                "login" // 重新回到登陆页
              );
              removeToken()
           
            // that.$router.push({ name: "login" })
           
              // that.$router.push({
              //   path: `/login`
              // })
              // location.reload()
        }
      }
    Message({
      message: error.msg || error,
      type: 'error',
      dangerouslyUseHTMLString: true,
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)
export default service
