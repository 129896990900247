import Cookies from 'js-cookie'
import Config from '@/config'
import store from '@/store'

const TokenKey = Config.TokenKey

export function getToken() {
  return Cookies.get(TokenKey)
}

export function getUserInfo() {
  const user = store.getters.user
  return user
}

// 菜单栏是否默认开启 新页签
export function isOpenMenuBlank() {
  return store.state.settings.openMenuBlank
}

export function setToken(token, rememberMe) {
  if (rememberMe) {
    return Cookies.set(TokenKey, token, { expires: Config.tokenCookieExpires })
  } else return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}
